import { getStudent } from "lib/firebase/students";
import { StudentProfile } from "lib/firebase/types";
import { useEffect, useState } from "react";
import { getSamuraiLevel, initialSamuraiLevel } from "utils/xp";

export default function useProfileQuery() {
  const [data, setData] = useState<StudentProfile | null>({
    firestore: null,
    samuraiInfo: initialSamuraiLevel,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getStudent();
        if (res) {
          const info = getSamuraiLevel(res.xp);
          setData({
            firestore: res,
            samuraiInfo: info,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    let mounted = true;

    if (mounted) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return { data, loading };
}
