import { doc, getDoc, increment, setDoc, updateDoc } from "firebase/firestore";
import { assignTypes, db } from "lib/firebase";
import { avatarList } from "utils/avatars";

import { AddStudentInput, StudentDb } from "./types";
import { isClassExistent } from "./classes";

export const addStudent = async (input: AddStudentInput) => {
  const docRef = doc(db, "students", input.email);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return;
  }

  const avatar = avatarList[Math.floor(Math.random() * avatarList.length)];

  await setDoc(docRef, {
    ...input,
    xp: 0,
    avatar,
  });
};

export const updateAvatar = async (avatar: string) => {
  const email = sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const docRef = doc(db, "students", email);

  await setDoc(docRef, { avatar }, { merge: true });
};

export const getStudent = async (email?: string): Promise<StudentDb | null> => {
  const dbEmail = email ?? sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const docRef = doc(db, "students", dbEmail).withConverter(
    assignTypes<StudentDb>()
  );

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }

  return null;
};

export const addStudentToClass = async (classCode: string) => {
  const canJoinClass = await isClassExistent(classCode);

  if (!canJoinClass) {
    throw new Error("Turma não encontrada");
  }

  const classRef = doc(db, "classes", classCode);

  const email = sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const docRef = doc(db, "students", email);

  await setDoc(docRef, { class: classRef }, { merge: true });
};

export const addXp = async (xp: number) => {
  const email = sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const docRef = doc(db, "students", email);

  await updateDoc(docRef, {
    xp: increment(xp),
  });
};
