import { Button, Stack, TextField, Typography, useTheme } from "@mui/material";
import { addStudentToClass } from "lib/firebase/students";
import React, { useState } from "react";

const JoinClass = () => {
  const theme = useTheme();
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleJoinClass = async () => {
    try {
      await addStudentToClass(code);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const transformedCode = event.target.value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, "");
    setCode(transformedCode);
  };

  return (
    <Stack
      borderRadius={2}
      padding={2}
      alignItems="center"
      spacing={2}
      sx={{
        border: `3px solid ${theme.palette.primary.dark}`,
      }}
    >
      <Typography variant="h6">Entre em uma turma!</Typography>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Código"
        value={code}
        onChange={handleChange}
        error={Boolean(error)}
        helperText={error}
        onBlur={() => setError(null)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleJoinClass}
        disabled={!code}
      >
        Entrar
      </Button>
    </Stack>
  );
};

export default JoinClass;
